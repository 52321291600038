
import { Component, Mixins } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';

import Header from '@/components/Header/Header.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import DynamicTable from '@/components/DynamicTable/DynamicTable.vue';
import AssignmentSection from '@/components/Assignment/AssignmentSection.vue';
import SearchInput from '@/components/shared/SearchInput.vue';
import Footer from '@/components/Footer/Footer.vue';
import DeleteConfirm from '@/components/AssignmentObject/DeleteConfirm.vue';
import Paginator from '@/components/shared/Paginator.vue';
import DeleteDeliveryConfirm from '@/components/Assignment/DeleteDeliveryConfirm.vue';

import PublicationModalFlow from '@/mixins/PublicationModalFlow';
import FloatingMenuFlow from '@/mixins/FloatingMenuFlow';

import {
  DTable,
  DTableBody,
  DTableHeader,
  IconsList,
  FloatingMenuItem,
  RouteName,
  ModalDataTransfer,
  ModalTipos,
  DataConfirmation,
  AssignmentObjectModel,
  Class,
  Delivery,
  DeleteDeliveryConfirmData,
  FilterEnum,
  FloatingMenuModel,
  DTableEvents,
  DTableHeaderBody,
  FilterData,
  FloatingMenuEvents,
} from '@/models';

import { Filtro, Pagination } from '@/models/api';

import {
  AssignmentObjectService,
  ModalService,
  DynamicTableService,
  FilterService,
} from '@/services';

import { EventBus, FilterUtils } from '@/utils';

const { DELIVERY_GRADE, ASSIGNMENT_OBJECT_SINGLE_ITEM } = DTableBody;
const { SIMPLE_HEADER_TYPE, FILTER_HEADER_TYPE } = DTableHeaderBody;
const {
  QUESTION, SORT_UP, SORT_DOWN, FILTER,
} = IconsList;
const { ASSIGNMENT_OBJECT_EDIT } = RouteName;
const { INFORMATIVE } = ModalTipos;
const { ON_FILTER, CLEAR_ALL_FILTERS, REMOVE_FILTER } = DTableEvents;
const {
  TITLE,
  AUTHOR,
  DELIVERY_DATE,
  PUBLICATION_DATE,
  GRADE,
  STATUS,
} = FilterEnum;
const { OPEN } = FloatingMenuEvents;

@Component({
  name: 'DeliveriesOfAssignmentsObjects',
  components: {
    Header,
    ButtonDefault,
    DynamicTable,
    AssignmentSection,
    SearchInput,
    Paginator,
    Footer,
  },
  computed: {
    ...mapGetters('assignmentObject', {
      getClasses: 'getClasses',
      getObject: 'getObject',
    }),
    ...mapGetters('deliveries', {
      getDeliveriesList: 'getDeliveriesList',
      getPagination: 'getPagination',
    }),
  },
  methods: {
    ...mapActions('deliveries', {
      searchDeliveryList: 'searchDeliveryList',
      deleteDelivery: 'deleteDelivery',
    }),
    ...mapActions('assignmentObject', {
      getAssignmentObject: 'getAssignmentObject',
    }),
  },
})
export default class DeliveriesOfAssignmentsObjects extends Mixins(
  PublicationModalFlow,
  FloatingMenuFlow,
) {
  private objectId: number;
  private classId: number;
  private deleteId: number;
  private deleteDeliveryId: number | null;
  private filterIcons = [SORT_UP, SORT_DOWN, FILTER];

  private getDeliveriesList: Delivery[];
  private getClasses: Class[];
  private getObject: AssignmentObjectModel;

  private getPagination: Pagination<Delivery>;

  private deleteDelivery: (id: number) => Promise<boolean>;

  private searchDeliveryList: ({
    objectId,
    classId,
    searchKey,
    page,
    filter,
  }: {
    objectId: number;
    classId: number;
    searchKey?: string;
    page?: number;
    filter?: string;
  }) => Promise<Pagination<Delivery>>;

  private getAssignmentObject: (objectId: number) => Promise<AssignmentObjectModel>;

  public singleItemHeader: DTableHeader[] = [{
    text: 'ID',
    hasTooltip: false,
    hasFilter: false,
    width: 5,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'CURSO',
    hasTooltip: false,
    hasFilter: false,
    width: 17,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'DISCIPLINA',
    hasTooltip: false,
    hasFilter: false,
    width: 17,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'TURMA',
    hasTooltip: false,
    hasFilter: false,
    width: 16,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'DATA DE\nPUBLICAÇÃO',
    hasTooltip: true,
    hasFilter: false,
    tooltipText: 'Data em que o objeto foi publicado da turma',
    tooltipIcon: QUESTION,
    width: 14,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'DATA ENTREGA\nREGULAR',
    hasTooltip: false,
    hasFilter: false,
    width: 14,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: 'DATA ENTREGA\nRECUPERACAO',
    hasTooltip: false,
    hasFilter: false,
    width: 14,
    type: SIMPLE_HEADER_TYPE,
  }, {
    hasTooltip: false,
    hasFilter: false,
    width: 3,
    grow: false,
    type: SIMPLE_HEADER_TYPE,
  }];

  public singleItem: DTable = {
    header: this.singleItemHeader,
    body: ASSIGNMENT_OBJECT_SINGLE_ITEM,
    data: [],
  };

  public deliveriesListHeader: DTableHeader[] = [{
    hasTooltip: false,
    hasFilter: false,
    width: 3,
    grow: false,
    type: SIMPLE_HEADER_TYPE,
  }, {
    text: TITLE,
    hasTooltip: false,
    hasFilter: true,
    filterIcon: this.filterIcons,
    width: 18,
    grow: true,
    type: FILTER_HEADER_TYPE,
  }, {
    text: AUTHOR,
    hasTooltip: false,
    hasFilter: true,
    filterIcon: this.filterIcons,
    width: 12,
    grow: true,
    type: FILTER_HEADER_TYPE,
  }, {
    text: DELIVERY_DATE,
    hasTooltip: true,
    hasFilter: true,
    filterIcon: this.filterIcons,
    tooltipText: 'Data em que o assignment foi submetido para avaliação',
    tooltipIcon: QUESTION,
    width: 16,
    grow: true,
    type: FILTER_HEADER_TYPE,
  }, {
    text: PUBLICATION_DATE,
    hasTooltip: true,
    hasFilter: true,
    filterIcon: this.filterIcons,
    tooltipText: 'Data em o assignment foi publicado no\nNetworking',
    tooltipIcon: QUESTION,
    width: 18,
    grow: true,
    type: FILTER_HEADER_TYPE,
  }, {
    text: GRADE,
    hasTooltip: false,
    hasFilter: true,
    filterIcon: this.filterIcons,
    width: 8,
    grow: false,
    type: FILTER_HEADER_TYPE,
  }, {
    text: STATUS,
    hasTooltip: false,
    hasFilter: true,
    filterIcon: this.filterIcons,
    width: 20,
    grow: true,
    type: FILTER_HEADER_TYPE,
  }, {
    hasTooltip: false,
    hasFilter: false,
    width: 5,
    grow: false,
    type: SIMPLE_HEADER_TYPE,
  }];

  public deliveriesList: DTable = {
    header: this.deliveriesListHeader,
    headerStyle: {
      bgColor: '#FFFFFF',
      bdRadius: 5,
    },
    body: DELIVERY_GRADE,
    data: [],
    noDataText: 'Nenhum assignment publicado.',
  };

  get pagination(): Pagination<Delivery> {
    return this.getPagination;
  }

  private searchKey: string;
  private all: FloatingMenuModel = {
    data: [],
    keyString: 'All',
  };

  mounted() {
    DynamicTableService.requestEnded();
    this.getInitialData();
    this.subscribeToEvents();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    EventBus.$on(ON_FILTER, this.onFilter);
    EventBus.$on(CLEAR_ALL_FILTERS, this.clearAllFilters);
    EventBus.$on(REMOVE_FILTER, this.onFilter);
  }

  private unsubscribeToEvents() {
    EventBus.$off(ON_FILTER, this.onFilter);
    EventBus.$off(CLEAR_ALL_FILTERS, this.clearAllFilters);
    EventBus.$off(REMOVE_FILTER, this.onFilter);
  }

  private goTo(routerName: RouteName, trabalhoId: string) {
    const route = {
      name: routerName,
      params: {
        trabalhoId,
      },
    };

    this.$router.push(route);
  }

  private getInitialData(): void {
    this.objectId = Number(this.$route.params.trabalhoId);
    this.classId = Number(this.$route.params.turmaId);

    this.updateSingleItemData();

    this.deliveriesList.data = this.getDeliveriesList;
  }

  public getDeliveryList(searchKey?: string, page?: number, filter?: string): Promise<boolean> {
    DynamicTableService.requestStarted();
    this.searchKey = searchKey as string;
    const { objectId, classId } = this;

    return new Promise((resolve, reject) => {
      this.searchDeliveryList({
        objectId,
        classId,
        searchKey,
        page,
        filter,
      })
        .then(({ filtros, current_page, last_page }) => {
          this.getInitialData();

          const query = { ...this.$router.currentRoute.query };

          if (current_page && last_page !== 1) {
            if (current_page !== Number(query.page)) query.page = String(current_page);
          } else delete query.page;

          if (searchKey) {
            if (searchKey !== query.search) {
              query.search = searchKey;
              delete query.page;
            }
          } else delete query.search;

          if (filtros) {
            if (filtros !== query.filter) {
              query.filter = filtros;
              delete query.page;
            }
          } else delete query.filter;

          if (JSON.stringify(this.$router.currentRoute.query) !== JSON.stringify(query)) {
            this.$router.push({ path: this.$router.currentRoute.path, query });
          }
          resolve(true);
        })
        .catch(reject)
        .finally(() => {
          DynamicTableService.requestEnded();
        });
    });
  }

  public onEdit(item: FloatingMenuItem) {
    this.goTo(ASSIGNMENT_OBJECT_EDIT, item.id.toString());
  }

  public onDelete(item: FloatingMenuItem) {
    const [assignmentObject] = this.singleItem.data as AssignmentObjectModel[];

    if (item.text === 'Excluir assignment') {
      this.confirmDeleteDelivery(item, assignmentObject.discipline.name);
      return;
    }

    this.deleteAssignmentObject(assignmentObject);
  }

  private deleteAssignmentObject(object: AssignmentObjectModel) {
    this.deleteId = object.id;

    if (object.deletable) {
      this.deleteTrabalho(object.discipline.name);
    } else {
      this.notDeletableMessageModal();
    }
  }

  private notDeletableMessageModal() {
    const modalData: ModalDataTransfer = {
      fechavel: true,
      component: INFORMATIVE,
      props: {
        // eslint-disable-next-line max-len
        phrase: 'Não foi possível realizar a exclusão pois\nexistem Assignments publicados ou\nsalvos como rascunho.',
      },
    };

    ModalService.abrirModal(modalData);
  }

  private deleteTrabalho(discipline: string) {
    const deleteConfirm: DataConfirmation = this.confirmData(
      'EXCLUIR',
      'CANCELAR',
      DeleteConfirm,
      discipline,
    );
    this.setLoadingPhrase('Excluindo objeto de networking...');

    setTimeout(() => {
      this.openModal(ModalTipos.CONFIRMACAO, deleteConfirm, true);
    });
  }

  private requestDeleteTrabalho(trabId: number) {
    AssignmentObjectService
      .deleteObject(trabId)
      .then(() => this.$router.back())
      .catch((erro: string) => console.error(erro))
      .finally(() => ModalService.fecharModal());
  }

  public publishRequest() {
    if (!this.deleteDeliveryId) {
      this.requestDeleteTrabalho(this.deleteId);
      return;
    }

    this.deleteDelivery(this.deleteDeliveryId)
      .then(async () => {
        const { page, filter } = this.$route.query;

        await this.getDeliveryList(this.searchKey, Number(page) || undefined, filter as string);

        this.updateSingleItemData();
        ModalService.fecharModal();
      })
      .catch((err) => {
        console.error(err);
        if (err !== 'Request cancelled') ModalService.fecharModal();
      })
      .finally(() => {
        this.deleteDeliveryId = null;
      });
  }

  private confirmDeleteDelivery(item: FloatingMenuItem, discipline: string) {
    const delivery = (this.deliveriesList.data as Delivery[])
      .find((d: Delivery) => d.id === item.id);
    this.deleteDeliveryId = item.id;

    const phraseData: DeleteDeliveryConfirmData = {
      student: (delivery?.profile?.name?.length
        ? delivery?.profile?.name
        : delivery?.profile?.nickname) as string,
      discipline,
    };

    const deleteDeliveryConfirm = this.confirmData<DeleteDeliveryConfirmData>(
      'EXCLUIR',
      'CANCELAR',
      DeleteDeliveryConfirm,
      phraseData,
    );

    this.setLoadingPhrase(`Excluindo assignment do ${phraseData.student}...`);

    setTimeout(() => {
      this.openModal<DeleteDeliveryConfirmData>(
        ModalTipos.CONFIRMACAO,
        deleteDeliveryConfirm,
        true,
      );
    });
  }

  public changePage(page: number) {
    EventBus.$emit(OPEN, this.all, false);
    const { filter } = this.$router.currentRoute.query;
    this.getDeliveryList(this.searchKey, page, filter as string | undefined);
  }

  public onOpenMenu({ keyString, data, requestData }: FloatingMenuModel, open: boolean) {
    if (open && keyString && requestData) {
      const previousFilter = this.$router.currentRoute.query.filter;

      FilterService.getFilters(
        this.objectId,
        this.classId,
        FilterUtils.floatingMenuToFilterParams(keyString as string, data, previousFilter as string),
      ).then((filters: Filtro) => {
        this.deliveriesList.header = this.deliveriesListHeader.map((header: DTableHeader) => {
          const h = header;
          if (header.text === keyString) h.filterItems = filters;
          else delete h.filterItems;
          return h;
        });
      }).catch(console.error);
    }
  }

  private onFilter(newFilter: FilterData) {
    EventBus.$emit(OPEN, this.all, false);

    const filterBase64 = Object.keys(newFilter).length
      ? FilterUtils.toBase64(newFilter)
      : undefined;
    const { page, filter } = this.$route.query;

    this.getDeliveryList(
      this.searchKey,
      filter === filterBase64 ? Number(page) : undefined,
      filterBase64);
  }

  private clearAllFilters() {
    EventBus.$emit(OPEN, this.all, false);
    this.getDeliveryList(this.searchKey);
  }

  private updateSingleItemData() {
    this.getAssignmentObject(this.objectId)
      .then(() => {
        const singleClass: Class | undefined = this.getClasses.find(
          (t: Class) => t.id === this.classId,
        );

        this.singleItem = {
          ...this.singleItem,
          data: [{ ...this.getObject, class: singleClass }],
        };
      })
      .catch((err) => {
        if (err) console.error(err);
      });
  }
}
