
import { Component, Prop, Vue } from 'vue-property-decorator';

import { DeleteDeliveryConfirmData } from '@/models';

@Component({
  name: 'DeleteDeliveryConfirm',
})
export default class DeleteDeliveryConfirm extends Vue {
  @Prop() data: DeleteDeliveryConfirmData;
}
